import { useEffect, useRef } from "react";
import {
  viewport,
  colors,
  font,
  lineHeight,
} from "@styles/cssinjs/helpers/variables.js";
import ResponsiveImage from "@components/atoms/image/ResponsiveImage";
import Link from "next/link";

export default function CTA({
  data,
  className,
  page,
  colorScheme,
  ctaBgDarkGreenColor,
  ctaBgLightGreenColor,
  isNewDesign,
  wrapperClass,
  containerWrapperClass,
}) {
  const hasImageOffset = data.image.offset;
  // util for cta "lg:pt-20 md:pt-10"
  const appMode = process.env.APP_MODE;
  const link = data?.link?.href?.[appMode] || data?.link?.href;
  const linkSecondary =
    data?.linkSecondary?.href?.[appMode] || data?.linkSecondary?.href;
  const isDownloadable =
    (data.hasOwnProperty("link") && data.link.downloadable) === true
      ? true
      : false;

  const anchorRef = useRef(null);
  const imageURL =
    data.hasOwnProperty("image") &&
    data.image.path !== "" &&
    data.image.hasOwnProperty("path")
      ? data.image.path.replace(/(.png|.jpg)/, "")
      : false;
  useEffect(() => {
    if (isDownloadable) {
      anchorRef.current.download = data.link.fileName;
    }
  });

  let buttonClass,
    headingColor,
    descColor,
    backgroundColor = "";

  if (colorScheme === "grey-white-blue") {
    buttonClass = "btn-primary";
    headingColor = "text-black";
    descColor = "text-grey";
    backgroundColor = `background-color: ${colors.primitive.grey[600]};`;
  } else if (colorScheme === "white-grey-black") {
    buttonClass = "btn-primary";
    headingColor = "text-black";
    descColor = "text-black";
    backgroundColor = `background-color: ${colors.primitive.white["default"]};`;
  }
  const linkAttrs = {};
  const linkSecondaryAttrs = {};
  if (
    data?.linkSecondary?.hasOwnProperty("isExternal") &&
    data?.linkSecondary?.isExternal
  ) {
    linkSecondaryAttrs.target = "_blank";
    linkSecondaryAttrs.rel = "noopener noreferrer";
  }
  if (data?.link?.hasOwnProperty("isExternal") && data?.link?.isExternal) {
    linkAttrs.target = "_blank";
    linkAttrs.rel = "noopener noreferrer";
  }
  return (
    <section className={`${className ? className : ""}`}>
      <div
        className={`container cta-contrast 
          ${ctaBgDarkGreenColor ? "cta-dark-green-theme" : ""} 
          ${ctaBgLightGreenColor ? "cta-light-green-theme" : ""} 
         has-image variable-color br-8 lg:d-fx lg:pt-10 lg:pb-10 ${containerWrapperClass}
        ${hasImageOffset ? "has-img-offset" : ""} 
        ${data.image.hasOwnProperty("prefixes") ? "md:pb-0" : ""}`}
      >
        <article
          className={`lg:maxw-md lg:mr-5 ${wrapperClass ? wrapperClass : ""}`}
        >
          <h2
            className={`text-lg lg:mb-3 ${headingColor}`}
            dangerouslySetInnerHTML={{ __html: data.heading }}
          ></h2>
          {data.hasOwnProperty("desc") && (
            <p
              className={`lg:mt-2 lg:mb-3 md:mt-2 md:mb-1 ${descColor}`}
              dangerouslySetInnerHTML={{ __html: data.desc }}
            ></p>
          )}
          {data.descTwo != undefined ? (
            <p
              className="lg:mb-3 md:mb-1"
              dangerouslySetInnerHTML={{ __html: data.descTwo }}
            ></p>
          ) : (
            ""
          )}
          {data.hasOwnProperty("link") && (
            <Link
              href={link}
              prefetch={false}
              className={`btn-lg lg:mt-1 md:mt-4 md:w-100 ${
                buttonClass ? buttonClass : "btn-ternary"
              }`}
              ref={anchorRef}
              {...linkAttrs}
              {...(data.link.id ? { id: data.link.id } : {})}
            >
              <span>{data.link.text}</span>
            </Link>
          )}
          {data.hasOwnProperty("linkSecondary") && (
            <Link
              href={linkSecondary}
              prefetch={false}
              className={`btn-lg md:w-100 lg:ml-5 md:mt-2 ${
                colorScheme === "grey-white-blue"
                  ? "btn-secondary"
                  : "btn-quaternary"
              }`}
              {...linkSecondaryAttrs}
              {...(data.linkSecondary.id ? { id: data.linkSecondary.id } : {})}
            >
              <span>{data.linkSecondary.text}</span>
            </Link>
          )}
          {data.hasOwnProperty("note") && (
            <p className="lg:mt-5 md:mt-5">{data.note}</p>
          )}
          <style jsx>{`
            @media (max-width: ${viewport.tablet.md}) {
              .cta-contrast.has-image {
                padding-bottom: 0px !important;
                background-color: black !important;
              }
            }
            ${backgroundColor
              ? `
                .variable-color {
                  ${backgroundColor}
                }
            `
              : ``}
            @media (min-width: ${viewport.tablet.sm}) {
              .cta-contrast h2 {
                ${page === "industries"
                  ? `
                font-size:${font.size.lg[1]}!important;
                line-height:${lineHeight[10]}!important;
            `
                  : ``};
              }
            }
            @media (max-width: ${viewport.tablet.sm}) {
              .cta-contrast h2 {
                ${page === "industries"
                  ? `
                font-size:${font.size.md[3]}!important;
                line-height:${lineHeight[8]}!important;`
                  : ``}
              }
            }
          `}</style>
        </article>
        {data.image.hasOwnProperty("src") ? (
          isNewDesign === true ? (
            <ResponsiveImage
              dpi={data.image.prefixes.dpi}
              src={data.image.src}
              alt={data.image.alt}
              mobile={data.image.prefixes.mobile}
              tablet={data.image.prefixes.tablet}
              hasMobileImg={data.image.hasMobileImg}
              hasTabletImg={data.image.hasTabletImg}
              wrapClass="dp-block md:mt-6"
            />
          ) : (
            <ResponsiveImage
              dpi={data.image.prefixes.dpi}
              mobile={data.image.prefixes.mobile}
              src={data.image.src}
              alt={data.image.alt}
              wrapClass="dp-block md:mt-6"
            />
          )
        ) : (
          <picture
            style={{
              maxWidth: `${
                data.image.hasOwnProperty("maxwidth")
                  ? data.image.maxwidth
                  : "460"
              }px`,
            }}
          >
            <source srcSet={`${imageURL}.webp`} type="image/webp" />
            <source srcSet={`${imageURL}.png`} type="image/png" />
            <img
              loading="lazy"
              src={`${imageURL}.png`}
              alt={data.image.alt}
              width={data.image.dimensions[0]}
              height={data.image.dimensions[1]}
            />
            <style jsx>{`
              picture {
                ${data.image.isFixedToBottom ? "top: unset !important;" : ""}
                ${data.image.offset === 100 ? "right : 100px !important" : ""}
              }
            `}</style>
          </picture>
        )}
      </div>
    </section>
  );
}
